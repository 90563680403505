export type Region = 'central asia' | 'southeast asia' | 'asia' | 'north europe' | 'south europe' |
  'central europe' | 'east europe' | 'europe' | 'middle east' | 'africa' | 'north america' |
  'south america' | 'central america' | 'caribbean' | 'oceania';
export type Continent = 'asia' | 'europe' | 'africa' | 'north america' | 'south america' |
  'central america' | 'oceania';
export type TravelRegion = 'asia' | 'europe' | 'oceania' | 'north america' | 'central & south america' | 'others' | 'all';

export interface CountryInfo {
  country: string;
  region: Region;
  continent: Continent;
  travelRegion?: TravelRegion;
}

export function getCountryInfo(phrase: string): CountryInfo | undefined {
  const info = countries.find(c => {
    const re = new RegExp(`(^|[^a-zA-Z])${c.country?.toLowerCase()}([^a-zA-Z]|$)`);
    return re.test(phrase.toLowerCase());
  });
  return !info ? info : ({
    ...info,
    travelRegion: getTravelRegion(info)
  });
}

function getTravelRegion(country?: CountryInfo): TravelRegion | undefined {
  switch(country?.continent) {
    case 'africa':
      return 'others';
    case 'asia':
      if(country.region === 'middle east' || country.region === 'central asia') {
        return 'others';
      }
      else if(country.country === 'azerbaijan' || country.country === 'armenia' || country.country === 'georgia') {
        return 'europe';
      }

      return 'asia';
    case 'europe':
    case 'north america':
    case 'oceania':
      return country.continent;
    case 'central america':
    case 'south america':
      return 'central & south america';
    default:
      return undefined;
  }
}

export function getRegionPath(value?: TravelRegion): string {
  switch(value) {
    case 'north america':
      return 'north-america';
    case 'central & south america':
      return 'central-south-america';
  }

  return value || 'others';
}

const countries: CountryInfo[] = [
  { "country": "afghanistan", "region": "central asia", "continent": "asia" },
  { "country": "armenia", "region": "asia", "continent": "asia" },
  { "country": "azerbaijan", "region": "asia", "continent": "asia" },
  { "country": "bahrain", "region": "middle east", "continent": "asia" },
  { "country": "bangladesh", "region": "asia", "continent": "asia" },
  { "country": "bhutan", "region": "asia", "continent": "asia" },
  { "country": "brunei", "region": "southeast asia", "continent": "asia" },
  { "country": "cambodia", "region": "southeast asia", "continent": "asia" },
  { "country": "china", "region": "asia", "continent": "asia" },
  { "country": "cyprus", "region": "middle east", "continent": "asia" },
  { "country": "georgia", "region": "asia", "continent": "asia" },
  { "country": "india", "region": "asia", "continent": "asia" },
  { "country": "indonesia", "region": "southeast asia", "continent": "asia" },
  { "country": "iran", "region": "middle east", "continent": "asia" },
  { "country": "iraq", "region": "middle east", "continent": "asia" },
  { "country": "israel", "region": "middle east", "continent": "asia" },
  { "country": "japan", "region": "asia", "continent": "asia" },
  { "country": "jordan", "region": "middle east", "continent": "asia" },
  { "country": "kazakhstan", "region": "central asia", "continent": "asia" },
  { "country": "kuwait", "region": "middle east", "continent": "asia" },
  { "country": "kyrgyzstan", "region": "central asia", "continent": "asia" },
  { "country": "laos", "region": "southeast asia", "continent": "asia" },
  { "country": "lebanon", "region": "middle east", "continent": "asia" },
  { "country": "malaysia", "region": "southeast asia", "continent": "asia" },
  { "country": "maldives", "region": "asia", "continent": "asia" },
  { "country": "mongolia", "region": "asia", "continent": "asia" },
  { "country": "myanmar", "region": "southeast asia", "continent": "asia" },
  { "country": "nepal", "region": "asia", "continent": "asia" },
  { "country": "north korea", "region": "asia", "continent": "asia" },
  { "country": "oman", "region": "middle east", "continent": "asia" },
  { "country": "pakistan", "region": "asia", "continent": "asia" },
  { "country": "palestine", "region": "middle east", "continent": "asia" },
  { "country": "philippines", "region": "southeast asia", "continent": "asia" },
  { "country": "qatar", "region": "middle east", "continent": "asia" },
  { "country": "saudi arabia", "region": "middle east", "continent": "asia" },
  { "country": "singapore", "region": "southeast asia", "continent": "asia" },
  { "country": "south korea", "region": "asia", "continent": "asia" },
  { "country": "sri lanka", "region": "asia", "continent": "asia" },
  { "country": "syria", "region": "middle east", "continent": "asia" },
  { "country": "taiwan", "region": "asia", "continent": "asia" },
  { "country": "tajikistan", "region": "central asia", "continent": "asia" },
  { "country": "thailand", "region": "southeast asia", "continent": "asia" },
  { "country": "timor-leste", "region": "southeast asia", "continent": "asia" },
  { "country": "turkey", "region": "middle east", "continent": "asia" },
  { "country": "turkmenistan", "region": "central asia", "continent": "asia" },
  { "country": "united arab emirates", "region": "middle east", "continent": "asia" },
  { "country": "uzbekistan", "region": "central asia", "continent": "asia" },
  { "country": "vietnam", "region": "southeast asia", "continent": "asia" },
  { "country": "yemen", "region": "middle east", "continent": "asia" },
  { "country": "albania", "region": "south europe", "continent": "europe" },
  { "country": "andorra", "region": "south europe", "continent": "europe" },
  { "country": "armenia", "region": "asia", "continent": "europe" },
  { "country": "austria", "region": "central europe", "continent": "europe" },
  { "country": "azerbaijan", "region": "asia", "continent": "europe" },
  { "country": "belarus", "region": "east europe", "continent": "europe" },
  { "country": "belgium", "region": "europe", "continent": "europe" },
  { "country": "bosnia and herzegovina", "region": "south europe", "continent": "europe" },
  { "country": "bulgaria", "region": "south europe", "continent": "europe" },
  { "country": "croatia", "region": "south europe", "continent": "europe" },
  { "country": "cyprus", "region": "middle east", "continent": "asia" },
  { "country": "czech republic", "region": "central europe", "continent": "europe" },
  { "country": "denmark", "region": "north europe", "continent": "europe" },
  { "country": "estonia", "region": "north europe", "continent": "europe" },
  { "country": "finland", "region": "north europe", "continent": "europe" },
  { "country": "france", "region": "europe", "continent": "europe" },
  { "country": "georgia", "region": "asia", "continent": "europe" },
  { "country": "germany", "region": "central europe", "continent": "europe" },
  { "country": "greece", "region": "south europe", "continent": "europe" },
  { "country": "hungary", "region": "central europe", "continent": "europe" },
  { "country": "iceland", "region": "north europe", "continent": "europe" },
  { "country": "ireland", "region": "europe", "continent": "europe" },
  { "country": "italy", "region": "south europe", "continent": "europe" },
  { "country": "kazakhstan", "region": "central asia", "continent": "asia" },
  { "country": "kosovo", "region": "south europe", "continent": "europe" },
  { "country": "latvia", "region": "north europe", "continent": "europe" },
  { "country": "liechtenstein", "region": "central europe", "continent": "europe" },
  { "country": "lithuania", "region": "north europe", "continent": "europe" },
  { "country": "luxembourg", "region": "europe", "continent": "europe" },
  { "country": "malta", "region": "south europe", "continent": "europe" },
  { "country": "moldova", "region": "east europe", "continent": "europe" },
  { "country": "monaco", "region": "south europe", "continent": "europe" },
  { "country": "montenegro", "region": "south europe", "continent": "europe" },
  { "country": "netherlands", "region": "europe", "continent": "europe" },
  { "country": "north macedonia", "region": "south europe", "continent": "europe" },
  { "country": "norway", "region": "north europe", "continent": "europe" },
  { "country": "poland", "region": "central europe", "continent": "europe" },
  { "country": "portugal", "region": "south europe", "continent": "europe" },
  { "country": "romania", "region": "east europe", "continent": "europe" },
  { "country": "russia", "region": "east europe", "continent": "europe" },
  { "country": "san marino", "region": "south europe", "continent": "europe" },
  { "country": "serbia", "region": "south europe", "continent": "europe" },
  { "country": "slovakia", "region": "central europe", "continent": "europe" },
  { "country": "slovenia", "region": "south europe", "continent": "europe" },
  { "country": "spain", "region": "south europe", "continent": "europe" },
  { "country": "sweden", "region": "north europe", "continent": "europe" },
  { "country": "switzerland", "region": "central europe", "continent": "europe" },
  { "country": "turkey", "region": "middle east", "continent": "asia" },
  { "country": "ukraine", "region": "east europe", "continent": "europe" },
  { "country": "united kingdom", "region": "europe", "continent": "europe" },
  { "country": "vatican city", "region": "south europe", "continent": "europe" },
  { "country": "argentina", "region": "south america", "continent": "south america" },
  { "country": "bolivia", "region": "south america", "continent": "south america" },
  { "country": "brazil", "region": "south america", "continent": "south america" },
  { "country": "chile", "region": "south america", "continent": "south america" },
  { "country": "colombia", "region": "south america", "continent": "south america" },
  { "country": "ecuador", "region": "south america", "continent": "south america" },
  { "country": "guyana", "region": "south america", "continent": "south america" },
  { "country": "paraguay", "region": "south america", "continent": "south america" },
  { "country": "peru", "region": "south america", "continent": "south america" },
  { "country": "suriname", "region": "south america", "continent": "south america" },
  { "country": "uruguay", "region": "south america", "continent": "south america" },
  { "country": "venezuela", "region": "south america", "continent": "south america" },
  { "country": "antigua and barbuda", "region": "caribbean", "continent": "north america" },
  { "country": "bahamas", "region": "caribbean", "continent": "north america" },
  { "country": "barbados", "region": "caribbean", "continent": "north america" },
  { "country": "jamaica", "region": "caribbean", "continent": "north america" },
  { "country": "cuba", "region": "caribbean", "continent": "north america" },
  { "country": "dominican republic", "region": "caribbean", "continent": "north america" },
  { "country": "saint kitts and nevis", "region": "caribbean", "continent": "north america" },
  { "country": "saint lucia", "region": "caribbean", "continent": "north america" },
  { "country": "saint vincent and the grenadines", "region": "caribbean", "continent": "north america" },
  { "country": "belize", "region": "central america", "continent": "central america" },
  { "country": "costa rica", "region": "central america", "continent": "central america" },
  { "country": "dominica", "region": "central america", "continent": "central america" },
  { "country": "el salvador", "region": "central america", "continent": "central america" },
  { "country": "grenada", "region": "central america", "continent": "central america" },
  { "country": "guatemala", "region": "central america", "continent": "central america" },
  { "country": "haiti", "region": "central america", "continent": "central america" },
  { "country": "honduras", "region": "central america", "continent": "central america" },
  { "country": "nicaragua", "region": "central america", "continent": "central america" },
  { "country": "panama", "region": "central america", "continent": "central america" },
  { "country": "trinidad and tobago", "region": "central america", "continent": "north america" },
  { "country": "united states", "region": "north america", "continent": "north america" },
  { "country": "canada", "region": "north america", "continent": "north america" },
  { "country": "mexico", "region": "north america", "continent": "north america" },
  { "country": "australia", "region": "oceania", "continent": "oceania" },
  { "country": "fiji", "region": "oceania", "continent": "oceania" },
  { "country": "kiribati", "region": "oceania", "continent": "oceania" },
  { "country": "marshall islands", "region": "oceania", "continent": "oceania" },
  { "country": "micronesia", "region": "oceania", "continent": "oceania" },
  { "country": "nauru", "region": "oceania", "continent": "oceania" },
  { "country": "new zealand", "region": "oceania", "continent": "oceania" },
  { "country": "palau", "region": "oceania", "continent": "oceania" },
  { "country": "papua new guinea", "region": "oceania", "continent": "oceania" },
  { "country": "samoa", "region": "oceania", "continent": "oceania" },
  { "country": "solomon islands", "region": "oceania", "continent": "oceania" },
  { "country": "tonga", "region": "oceania", "continent": "oceania" },
  { "country": "tuvalu", "region": "oceania", "continent": "oceania" },
  { "country": "vanuatu", "region": "oceania", "continent": "oceania" },
  { "country": "algeria", "region": "africa", "continent": "africa" },
  { "country": "angola", "region": "africa", "continent": "africa" },
  { "country": "benin", "region": "africa", "continent": "africa" },
  { "country": "botswana", "region": "africa", "continent": "africa" },
  { "country": "burkina faso", "region": "africa", "continent": "africa" },
  { "country": "burundi", "region": "africa", "continent": "africa" },
  { "country": "cabo verde", "region": "africa", "continent": "africa" },
  { "country": "cameroon", "region": "africa", "continent": "africa" },
  { "country": "central african rep", "region": "africa", "continent": "africa" },
  { "country": "chad", "region": "africa", "continent": "africa" },
  { "country": "comoros", "region": "africa", "continent": "africa" },
  { "country": "democratic republic of the congo", "region": "africa", "continent": "africa" },
  { "country": "dem. rep. congo", "region": "africa", "continent": "africa" },
  { "country": "republic of the congo", "region": "africa", "continent": "africa" },
  { "country": "congo", "region": "africa", "continent": "africa" },
  { "country": "djibouti", "region": "africa", "continent": "africa" },
  { "country": "egypt", "region": "africa", "continent": "africa" },
  { "country": "equatorial guinea", "region": "africa", "continent": "africa" },
  { "country": "eritrea", "region": "africa", "continent": "africa" },
  { "country": "eswatini", "region": "africa", "continent": "africa" },
  { "country": "ethiopia", "region": "africa", "continent": "africa" },
  { "country": "gabon", "region": "africa", "continent": "africa" },
  { "country": "gambia", "region": "africa", "continent": "africa" },
  { "country": "ghana", "region": "africa", "continent": "africa" },
  { "country": "guinea", "region": "africa", "continent": "africa" },
  { "country": "guinea-bissau", "region": "africa", "continent": "africa" },
  { "country": "ivory coast", "region": "africa", "continent": "africa" },
  { "country": "kenya", "region": "africa", "continent": "africa" },
  { "country": "lesotho", "region": "africa", "continent": "africa" },
  { "country": "liberia", "region": "africa", "continent": "africa" },
  { "country": "libya", "region": "africa", "continent": "africa" },
  { "country": "madagascar", "region": "africa", "continent": "africa" },
  { "country": "malawi", "region": "africa", "continent": "africa" },
  { "country": "mali", "region": "africa", "continent": "africa" },
  { "country": "mauritania", "region": "africa", "continent": "africa" },
  { "country": "mauritius", "region": "africa", "continent": "africa" },
  { "country": "morocco", "region": "africa", "continent": "africa" },
  { "country": "mozambique", "region": "africa", "continent": "africa" },
  { "country": "namibia", "region": "africa", "continent": "africa" },
  { "country": "niger", "region": "africa", "continent": "africa" },
  { "country": "nigeria", "region": "africa", "continent": "africa" },
  { "country": "rwanda", "region": "africa", "continent": "africa" },
  { "country": "sao tome and principe", "region": "africa", "continent": "africa" },
  { "country": "senegal", "region": "africa", "continent": "africa" },
  { "country": "seychelles", "region": "africa", "continent": "africa" },
  { "country": "sierra leone", "region": "africa", "continent": "africa" },
  { "country": "somalia", "region": "africa", "continent": "africa" },
  { "country": "south africa", "region": "africa", "continent": "africa" },
  { "country": "south sudan", "region": "africa", "continent": "africa" },
  { "country": "sudan", "region": "africa", "continent": "africa" },
  { "country": "tanzania", "region": "africa", "continent": "africa" },
  { "country": "togo", "region": "africa", "continent": "africa" },
  { "country": "tunisia", "region": "africa", "continent": "africa" },
  { "country": "uganda", "region": "africa", "continent": "africa" },
  { "country": "zambia", "region": "africa", "continent": "africa" },
  { "country": "zimbabwe", "region": "africa", "continent": "africa" },
  { "country": "côte d'ivoire", "region": "africa", "continent": "africa" }
];
