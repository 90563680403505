import { Button, Card, CardContent, CardHeader, List, ListItem, Typography } from "@mui/material";
import "./LoginComponent.scss";

interface LoginComponentProps {
  cancel?: () => void;
  completed?: () => void;
  returnUrl?: string;
  title?: string;
  label?: string;
  className?: string;
}

export function LoginComponent({ cancel, completed, returnUrl, title, label, className }: LoginComponentProps) {
  const param = returnUrl ? `?returnTo=${encodeURIComponent(returnUrl)}` :
    completed ? `?messageType=AUTH_SUCCESS` : '';
  const handleGoogle = () => openAuth(`/auth/google${param}`, completed);
  const handleFacebook = () => openAuth(`/auth/facebook${param}`, completed);
  return <Card className={`login-component-container ${className}`}>
    {!!title ?
      <CardHeader title={<Typography variant="h6">{title}</Typography>} /> : null
    }
    <CardContent>
      <div className='login-label'>
        {label || 'Sign in to build your itinerary...'}
      </div>
      <List>
        <ListItem className='item-button'>
          <Button variant='contained' color="primary" onClick={handleGoogle}>
            Sign in with Google
          </Button>
        </ListItem>
        <ListItem className='item-button'>
          <Button variant='contained' color="primary" onClick={handleFacebook}>
            Sign in with Facebook
          </Button>
        </ListItem>
      </List>
      {!!cancel ?
        <div className='action-pane'>
          <Button onClick={cancel} variant='outlined'>Cancel</Button>
        </div> : null
      }
    </CardContent>
  </Card>;
}

function openAuth(url: string, closeCallback?: () => void) {
  if(!closeCallback) {
    window.location.href = url;
    return;
  }

  const width = 500;
  const height = 600;

  // Get the browser's position on the screen setup
  const dualScreenLeft = window.screenX || window.screenLeft;
  const dualScreenTop = window.screenY || window.screenTop;

  // Get the browser's width and height
  const browserWidth = window.innerWidth || document.documentElement.clientWidth || screen.width;
  const browserHeight = window.innerHeight || document.documentElement.clientHeight || screen.height;

  // Calculate the centered position relative to the current monitor
  const left = dualScreenLeft + (browserWidth - width) / 2;
  const top = dualScreenTop + (browserHeight - height) / 2;

  const authWindow = window.open(
    url,
    'loginWindow',
    `width=${width},height=${height},top=${top},left=${left}`
  );

  // Optional: Listen for messages from popup
  const checkPopup = setInterval(() => {
    if(authWindow?.closed) {
      clearInterval(checkPopup);
      closeCallback();
    }
  }, 500);
};
