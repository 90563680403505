import removeAccents from 'remove-accents';

export function removeAccents2(str: string): string {
  // remove punctuation chars too
  return removeAccents(str).replace(/[^\w\s]|_/g, "");
}

// compare string ignor accents
export function sameString(s1: string, s2: string): boolean {
  const str1 = removeAccents2(s1 || '').toLowerCase();
  const str2 = removeAccents2(s2 || '').toLowerCase();

  return str1 === str2;
}

export function includesSameString(arr: string[], str: string): boolean {
  return !!arr.find(a => sameString(a, str));
}

export function startsWithSameString(s1: string, s2: string): boolean {
  return removeAccents2(s1).toLowerCase().startsWith(removeAccents2(s2).toLowerCase());
}
