import { Attraction } from "@shared/types/itinerary";
import {
  getAppName, getAttractionGuideJsonDescription, getAttractionGuideTitleSeo, getCityGuideJsonDescription,
  getCityGuideTitleSeo, getFeaturedTripsJsonDescription, getFeaturedTripsTitle, getHomeJsonDescription,
  getHomeMetaDescription, getOutlineJsonDescription, getSiteName, getTripChatJsonDescription
} from "./seoMeta";
import { getLocationCity, getLocationCountry } from "./shareUtil";

export function getHomeLdJson(): string {
  return `{
  "@context": "https://schema.org",
  "@type": "WebSite",
  "name": "Map&Hat: ${getSiteName()}",
  "description": "${getHomeJsonDescription()}",
  "url": "https://mapnhat.com",
  "publisher": {
    "@type": "Organization",
    "name": "Map&Hat",
    "url": "https://mapnhat.com"
  }
}`;
}

export function getCreateLdJson(): string {
  return `{
  "@context": "https://schema.org",
  "@type": "WebApplication",
  "name": "${getAppName()}",
  "url": "https://mapnhat.com/create.html",
  "applicationCategory": "TravelApplication",
  "operatingSystem": "All",
  "browserRequirements": "Requires JavaScript",
  "description": "${getTripChatJsonDescription()}",
  "offers": {
    "@type": "Offer",
    "priceCurrency": "USD",
    "price": "0"
  },
  "isPartOf": {
    "@type": "WebSite",
    "name": "Map&Hat",
    "url": "https://mapnhat.com"
  }
}`;
}

export function getFeaturedTripsLdJson(): string {
  return `{
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": "${getFeaturedTripsTitle()}",
  "description": "${getFeaturedTripsJsonDescription()}",
  "author": {
    "@type": "Organization",
    "name": "Map&Hat",
    "url": "https://mapnhat.com"
  },
  "isPartOf": {
    "@type": "WebApplication",
    "name": "${getAppName()}",
    "url": "https://mapnhat.com/create.html",
    "applicationCategory": "TravelApplication",
    "operatingSystem": "All"
  },
  "provider": {
    "@type": "Organization",
    "name": "Map&Hat"
  }
}`;
}

export function getOutlineLdJson(tripName?: string, destinations?: string): string {
  return `{
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": "Itinerary | ${tripName}",
  "description": "${getOutlineJsonDescription(destinations)}",
  "author": {
    "@type": "Organization",
    "name": "Map&Hat",
    "url": "https://mapnhat.com"
  },
  "isPartOf": {
    "@type": "WebApplication",
    "name": "${getAppName()}",
    "url": "https://mapnhat.com/create.html",
    "applicationCategory": "TravelApplication",
    "operatingSystem": "All"
  },
  "provider": {
    "@type": "Organization",
    "name": "Map&Hat"
  }
}`;
}

export function getCityGuideLdJson(location: string, attractions: Attraction[]): string {
  return `{
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": "${getCityGuideTitleSeo(location)}",
  "description": "${getCityGuideJsonDescription(location)}",
  "about": "City guide, top attractions, transportation",
  "hasPart": [
    ${getAttractionSchema(attractions)}
  ],
  "isPartOf": {
    "@type": "WebApplication",
    "name": "${getAppName()}",
    "url": "https://mapnhat.com/create.html",
    "applicationCategory": "TravelApplication",
    "operatingSystem": "All"
  },
  "author": {
    "@type": "Organization",
    "name": "Map&Hat",
    "url": "https://mapnhat.com"
  }
}`;
}

function getAttractionSchema(attractions: Attraction[]): string {
  return attractions?.map(a => `{
    "@type": "Article",
    "headline": "Attraction Guide | ${a.place}",
    "description": "${getAttractionGuideJsonDescription(a.location, a.place)}",
    "url": "https://mapnhat.com/attraction-guide/${getLocationCountry(a.location)}/${getLocationCity(a.location)}/${a.place}"
  }`).join(',\n') || '';
}

export function getAttractionLdJson(location: string, place?: string): string {
  return `{
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": "${getAttractionGuideTitleSeo(location, place)}",
  "description": "${getAttractionGuideJsonDescription(location, place)}",
  "author": {
    "@type": "Organization",
    "name": "Map&Hat",
    "url": "https://mapnhat.com"
  },
  "isPartOf": {
    "@type": "WebApplication",
    "name": "${getAppName()}",
    "url": "https://mapnhat.com/create.html",
    "applicationCategory": "TravelApplication",
    "operatingSystem": "All"
  }
}`;
}