import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from './page/Login';
import Profile from './page/Profile';
import ProtectedRoute from './widget/ProtectedRoute';
import ConfirmDialog from './widget/ConfirmDialog';
import "./global.scss";
import "./App.scss";
import { useUserContext } from './service/UserContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { initGA } from './util/analytics';
import { initAxios } from './util/initAxios';
import LoadingScreen from './widget/LoadingScreen';
import { AppToolbar } from './component/AppToolbar';
import MobileAppBar from './mobile/MobileAppBar';
import { isMobile } from './util/mobile';
import CreatePage from './mobile/CreatePage';

const App: React.FC = () => {
  const [error, setError] = useState<string>("");
  const userManager = useUserContext();
  const [mobile, setMobile] = useState(isMobile());

  useEffect(() => {
    // Initialize Google Analytics only once
    initGA();

    // preload
    if('requestIdleCallback' in window) {
      requestIdleCallback(() => {
        import('./page/Home');
        import('./page/FeaturedTrips');
        import('./page/Outline');
        import('./page/MyTrips');
        import('./page/CityGuidePage');
        import('./page/CityGuidesPage');
        import('./page/AttractionGuidePage');
        import('./page/InfoPage');
        import('./page/AdminPage');

        if(!mobile) {
          import('./page/TripChat');
        }
      });
    }
  }, []);

  interface MainContainerProps {
    children?: ReactNode;
  }

  const MainContainer: React.FC<MainContainerProps> = ({ children }) => {
    const location = useLocation();
    const hasBg = location.pathname === '/' || location.pathname.startsWith('/home') ||
      location.pathname.startsWith('/featured-trips') || location.pathname.startsWith('/mytrips') ||
      location.pathname.startsWith('/admin') || location.pathname.startsWith('/login');
    const editor = location.pathname.startsWith('/create') || location.pathname.startsWith('/open');
    return <div>
      {editor ? null : mobile ? <MobileAppBar /> : <AppToolbar />}
      <div className={`main-container ${!hasBg ? 'no-bg' : ''}`}>{children}</div>
    </div>;
  }

  initAxios(setError);
  userManager.init();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#5e84c5',
      },
      secondary: {
        main: '#8991a2',
      },
    },
  });

  const Home = React.lazy(() => import('./page/Home'));
  const FeaturedTrips = React.lazy(() => import('./page/FeaturedTrips'));
  const TripChat = React.lazy(() => import('./page/TripChat'));
  const Outline = React.lazy(() => import('./page/Outline'));
  const MyTrips = React.lazy(() => import('./page/MyTrips'));
  const CityGuidePage = React.lazy(() => import('./page/CityGuidePage'));
  const CityGuidesPage = React.lazy(() => import('./page/CityGuidesPage'));
  const AttractionGuidePage = React.lazy(() => import('./page/AttractionGuidePage'));
  const AdminPage = React.lazy(() => import('./page/AdminPage'));
  const InfoPage = React.lazy(() => import('./page/InfoPage'));

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <MainContainer>
          <Suspense fallback={<LoadingScreen />}>
            <Routes>
              <Route path="/login" Component={Login} />
              <Route path="/mytrips" element={<ProtectedRoute><MyTrips /></ProtectedRoute>} />
              <Route path="/" element={mobile ? <FeaturedTrips region='all' /> : <Home />} />
              <Route path="/home" element={mobile ? <FeaturedTrips region='all' /> : <Home />} />
              <Route path="/featured-trips" element={<FeaturedTrips/>} />
              <Route path="/featured-trips/asia" element={<FeaturedTrips region='asia' />} />
              <Route path="/featured-trips/europe" element={<FeaturedTrips region='europe' />} />
              <Route path="/featured-trips/north-america" element={<FeaturedTrips region='north america' />} />
              <Route path="/featured-trips/central-south-america" element={<FeaturedTrips region='central & south america' />} />
              <Route path="/featured-trips/oceania" element={<FeaturedTrips region='oceania' />} />
              <Route path="/featured-trips/others" element={<FeaturedTrips region='others' />} />
              <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
              <Route path="/create" element={<TripChat />} />
              <Route path="/open/:_id" element={<TripChat />} />
              <Route path="/outline/:_id" element={<Outline />} />
              <Route path="/city-guide/:country/:city" element={<CityGuidePage />} />
              <Route path="/attraction-guide/:country/:city/:place" element={<AttractionGuidePage />} />
              <Route path="/city-guides" element={<CityGuidesPage />} />
              <Route path="/admin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
              <Route path="/info/:page" element={<InfoPage />} />
              <Route path="/editor-info" element={<CreatePage />} />
              <Route path="*" element={<Navigate to='/' />} />
            </Routes>
          </Suspense>
        </MainContainer>
        <ConfirmDialog open={!!error} label={error} buttons={['OK']} onSubmit={() => window.location.href = '/'} />
      </Router>
    </ThemeProvider>
  );
};

export default App;
