import { removeAccents2, sameString } from "./sameString";

// check if two place names refer to the same attraction.
export function isSamePlace(p1: string, p2: string): boolean {
  const s1 = removeAccents2(p1).toLowerCase();
  const s2 = removeAccents2(p2).toLowerCase();
  const name1 = p1.split(' ').sort();
  const name2 = p2.split(' ').sort();

  // phrase with words in different orders are considered same name (e.g. Larco Museum === Museum Larco)
  if(name1 === name2) {
    return true;
  }

  // name that is a substring of another name is considered a duplicate.
  return s1.includes(s2) || s2.includes(s1);
}

export function isSameCity(s1: string, s2: string): boolean {
  const normalizeCity = (s: string) => {
    return s.replace(/^Saint /, "St ").replace(/^St\. /, "St ")
      .replaceAll('Korca', 'Korçë').replaceAll('Kruja', 'Kruje').replaceAll('Korça', 'Korçë')
      .replaceAll('Vlora', 'Vlorë');
  };
  return sameString(normalizeCity(s1), normalizeCity(s2));
}