import React from 'react';
import { Card, CardContent, CardMedia, Button, Typography, Container, Grid } from '@mui/material';
import './CreatePage.scss';

const CreatePage: React.FC = () => {
  return (
    <div className='create-page-container'>
      <header className='create-page-header'>
        <Typography variant="h4" component="h1">
          AI Itinerary Builder
        </Typography>
        <Typography variant="h6" paragraph>
          Plan your perfect trip with ease using our AI powered interactive itinerary builder.
        </Typography>
      </header>

      <Container maxWidth="lg" className='first-card'>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                  Create Your Perfect Itinerary
                </Typography>
                <Typography variant="body1">
                  Our AI-powered itinerary builder makes planning your trip a breeze. Simply chat with our chatbot to
                  create a personalized itinerary that suits your interests. You can visualize your trip on an interactive
                  map, modify plans in real time, and add detailed reservations and flight information on a calendar.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Second Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                  Access the Feature on Desktop
                </Typography>
                <Typography variant="body1">
                  We believe travel planning deserves more than just swiping through pictures and
                  reading short descriptions on a small screen. Our interactive itinerary builder
                  harnesses the power of AI to bring all the essential details together in one
                  place—allowing you to explore, visualize, and refine your plans with ease.
                  For the best experience, it’s optimized for larger screens, where you can fully
                  utilize features like detailed planning, interactive maps, and calendar scheduling.
                  Enjoy the complete functionality on a desktop or laptop for a seamless planning experience.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Screenshot Card */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                  Screenshot of the Interactive Itinerary Builder
                </Typography>
                <Typography variant="body1">
                  Visualize your trip in a map and modify plans interactively with chatbot.
                </Typography>
              </CardContent>
              <CardMedia component="img" image="/images/tripchat.png" alt="Interactive Itinerary Builder Screenshot"/>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CreatePage;
