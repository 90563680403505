import { getCreateLdJson, getHomeLdJson } from "./ldJson";
import { getLocationCity } from "./shareUtil";

export function getAppName(): string {
  return `AI Itinerary Builder`;
}

export function getSiteName(): string {
  return `AI Trip Planner`;
}

function getTitlePrefix(): string {
  return 'AI Itinerary Builder';
}

export function getHomeTitle(): string {
  return `Best ${getSiteName()} | Free ${getAppName()}`;
}

export function getHomeMetaDescription(): string {
  return `Plan trips easily with our free AI-powered itinerary planner. ` +
    `Customize plans, explore guides, and view routes on an interactive map.`;
}

export function getHomeJsonDescription(): string {
  return `Free AI powered itinerary planner with interactive editing, pre-built plans, city guides, ` +
    `and mapped routes for seamless self - planned travel.`;
}

export function getTripChatTitle(): string {
  return `Map&Hat | ${getAppName()}`;
}

export function getTripChatMetaDescription(): string {
  return `Create and customize trips with our AI-powered itinerary planner. ` +
    `Use interactive tools to modify plans and visualize routes on a map.`;
}

export function getTripChatJsonDescription(): string {
  return `Interactive AI itinerary planner for self-planned travel. Customize trips, edit plans in real-time, ` +
    `and map routes for easy travel planning.`;
}

export function getAboutTitle(): string {
  return `About mapNHat: Best ${getSiteName()}`;
}

export function getAboutMetaDescription(): string {
  return `Learn about our app, its features, and our travel planning philosophy. ` +
    `Contact us for support and get to know how we make trip planning easy.`;
}

export function getAboutJsonDescription(): string {
  return `Discover the story behind our app, our travel planning philosophy, and features. ` +
    `Find contact details for support and learn how we assist in trip planning.`;
}

export function getMyTripsTitle() {
  return `${getTitlePrefix()} | My Itineraries`;
}

export function getFeaturedTripsTitle() {
  return `${getTitlePrefix()} | Featured Itineraries`;
}

export function getFeaturedTripsMetaDescription() {
  return `Explore hundreds of pre-built travel itineraries. Find detailed trip plans for destinations worldwide ` +
    `and customize them to fit your style.`;
}

export function getFeaturedTripsJsonDescription() {
  return `Browse pre-built travel itineraries for destinations worldwide. ` +
    `Each plan includes detailed activities, routes, and customization options.`;
}

export function getOutlineTitle(tripName?: string) {
  return `${getTitlePrefix()} | Itinerary: ${tripName || ''}`;
}

export function getOutlineMetaDescription(destinations: string): string {
  return `Discover the perfect ${destinations} itinerary. ` +
    `Explore detailed plans, top attractions, and customize your trip today.`;
}

export function getOutlineJsonDescription(destinations: string): string {
  return `Plan your trip to ${destinations} with this detailed itinerary. ` +
    `Includes attractions, routes, and flexible customization options.`;
}

export function getCountryCityGuideTitle(country?: string): string {
  return `${getTitlePrefix()} | ${country} Top Destinations, Must-See Sights and Attractions`;
}

export function getCountryCityGuideMetaDescription(country?: string): string {
  return `Explore city guides for ${country}. Discover top attractions, travel tips, and ` +
    `recommendations for cities across the country.`;
}

export function getCountryCityGuideJsonDescription(country?: string): string {
  return `Find comprehensive city guides for ${country}. Includes attractions, local tips, ` +
    `and must - know travel information for cities nationwide.`;
}

export function getCityGuideTitle(location?: string): string {
  return `${getTitlePrefix()} | ${getLocationCity(location)} Top Attractions`;
}

export function getCityGuideTitleSeo(location?: string): string {
  return `${getTitlePrefix()} | Your Ultimate Guide to ${location}: Top Attractions, Activities, and Must-See Sights`;
}

export function getCityGuideMetaDescription(location?: string): string {
  return `Discover the ultimate travel guide to ${getLocationCity(location)}. ` +
    `Explore top attractions, local tips, and essential information for planning your visit. ` +
    `Then create you trip to ${location} using our AI Itinerary Builder.`;
}

export function getCityGuideJsonDescription(location?: string): string {
  return `Comprehensive travel guide for ${getLocationCity(location)}. ` +
    `Features top attractions, local insights, and key details to help you plan the perfect trip.`;
}

export function getAttractionGuideTitle(location?: string, place?: string): string {
  return `${getTitlePrefix()} | ${getLocationCity(location)} Attraction: ${place}`;
}

export function getAttractionGuideTitleSeo(location?: string, place?: string): string {
  return `${getTitlePrefix()} | Ultimate Attraction Guide: ${place}, ${location}`;
}

export function getAttractionGuideMetaDescription(location?: string, place?: string): string {
  return `Learn about ${place} in ${getLocationCity(location)}. ` +
    `Discover key details, tips, and must - know information to make the most of your visit. ` +
    `Create your trip to ${location} to visit ${place} using our AI Itinerary Builder.`;
}

export function getAttractionGuideJsonDescription(location?: string, place?: string): string {
  return `Explore ${place} in ${getLocationCity(location)} with our detailed guide. ` +
    `Includes history, highlights, visitor tips, and essential travel information.`;
}

export function getCityGuidesTitle(): string {
  return `${getTitlePrefix()} | World City Guides`;
}

export function getCityGuidesTitleSeo(): string {
  return `${getTitlePrefix()} | Ultimate World City Guides: Top Attractions, Activities, and Must-See Sights`;
}

export function getCityGuidesMetaDescription(): string {
  return `Browse city guides worldwide. Discover top attractions, travel tips, ` +
    `and essential information for cities across the globe.`;
}

export function getCityGuidesJsonDescription(): string {
  return `Explore city guides from around the world. Find detailed travel tips, top attractions, ` +
    `and practical information for your next destination.`;
}

export function getMetaTags() {
  return {
    'app.name': getAppName(),
    'site.name': getSiteName(),
    'home.title': getHomeTitle(),
    'home.meta.description': getHomeMetaDescription(),
    'home.json.description': getHomeJsonDescription(),
    'home.ld.json': getHomeLdJson(),
    'create.title': getTripChatTitle(),
    'create.meta.description': getTripChatMetaDescription(),
    'create.json.description': getTripChatJsonDescription(),
    'create.ld.json': getCreateLdJson(),
    'about.title': getAboutTitle(),
    'about.meta.description': getAboutMetaDescription(),
    'about.json.description': getAboutJsonDescription()
  };
}